<template>
  <div>
    <div class="title">资产总览</div>
    <div class="flex-row top-view" @click="$router.push({ path: '/invest-all/detail' })">
      <div class="flex-column-center">
        <div>资产总额(万美元)</div>
        <div class="text-org">999,000.0</div>
      </div>
      <div class="flex-column-center">
        <div>已投入金额(万美元)</div>
        <div class="text-org">999,000.0</div>
      </div>
      <div class="flex-column-center">
        <div>已锁定项目金额(万美元)</div>
        <div class="text-org">999,000.0</div>
      </div>
      <div class="flex-column-center">
        <div>可用余额(万美元)</div>
        <div class="text-org">999,000.0</div>
      </div>
    </div>
    <div class="title">收益总览</div>
    <div
      class="flex-row top-view"
      @click="$router.push({ path: '/invest-all/detail' })"
      style="border:none"
    >
      <div class="flex-column-center">
        <div>累计收益总金额(万美元)</div>
        <div class="text-org">999,000.0</div>
      </div>
      <div class="flex-column-center">
        <div>待收益金额(万美元)</div>
        <div class="text-org">999,000.0</div>
      </div>
    </div>
    <chart-pie title="訪問用戶數" />
  </div>
</template>

<script>
import chartPie from '../../components/chart-pie';
export default {
  components: {
    chartPie
  },
  data() {
    return {
      form: {
        name: ''
      },
      tableData: [
        {
          id: 'LT000239',
          name: '应收账款',
          price: '1000',
          term: '3/6',
          type1: '已签约',
          type2: '156.0',
          type3: '还款中',
          date: '2020-04-27',
          zip: '10'
        },
        {
          id: 'LT000343',
          name: '应收账款',
          price: '500',
          term: '1/1',
          type1: '已签约',
          type2: '380.0',
          type3: '还款中',
          date: '2020-05-02',
          zip: '120'
        },
        {
          id: 'LT000366',
          name: '应收账款',
          price: '805',
          term: '2/30',
          type1: '已签约',
          type2: '56.0',
          type3: '已完成',
          date: '2020-06-08',
          zip: '430'
        },
        {
          id: 'LT000399',
          name: '应收账款',
          price: '20,000',
          term: '11/15',
          type1: '已签约',
          type2: '200.0',
          type3: '已完成',
          date: '2020-08-19',
          zip: '800'
        }
      ],
      btnList: [
        { title: '全部', select: true },
        { title: '收益', select: false },
        { title: '投资', select: false },
        { title: '提现', select: false }
      ]
    };
  },
  onLoad() { },
  methods: {
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background:#81d8d0;color:#fff;';
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.top-view {
  height: 90px;
  border-bottom: 1px solid #979797;
  margin-bottom: 20px;
  line-height: 30px;
  > div {
    flex: 1;
    border-left: 1px solid #979797;
  }
  > div:nth-child(1) {
    border: none;
  }
  // > div:nth-child(2) {
  //   border-left: 1px solid #979797;
  //   border-right: 1px solid #979797;
  // }
}
.text-org {
  line-height: 20px;
  margin-top: 4px;
  font-size: 20px;
  border-bottom: 1px solid #81D8D0;
}
.btn-gray {
  background: #dddcdb !important;
  border-color: #dddcdb !important;
  color: white !important;
}
.btn-gray:focus {
  background: #ffcc80 !important;
  border-color: #ffcc80 !important;
}
.find-view {
  margin-left: 100px;
  .search-date {
    width: 450px;
    margin-bottom: 0;
    .el-form-item,
    .el-form-item__label,
    .el-form-item__content {
      display: inline-block;
    }
    .el-input,
    .el-input__inner {
      width: 150px;
    }
  }
}
</style>
